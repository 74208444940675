/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useTranslation } from "react-i18next";

const languages = [
  {
    lang: "en",
    short: "EN",
    name: "EN-English",
    flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
  },
  {
    lang: "zh",
    short: "中文",
    name: "中文-Chinese",
    flag: toAbsoluteUrl("/media/svg/flags/015-china.svg"),
  },
  {
    lang: "it",
    short: "IT",
    name: "IT-Italian",
    flag: toAbsoluteUrl("/media/svg/flags/013-italy.svg"),
  },
  {
    lang: "es",
    short: "ES",
    name: "ES-Spanish",
    flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg"),
  },
  // {
  //   lang: "ja",
  //   name: "Japanese",
  //   flag: toAbsoluteUrl("/media/svg/flags/063-japan.svg"),
  // },
  // {
  //   lang: "de",
  //   name: "German",
  //   flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg"),
  // },
  // {
  //   lang: "fr",
  //   name: "French",
  //   flag: toAbsoluteUrl("/media/svg/flags/195-france.svg"),
  // },
];

export function LanguageSelectorDropdown() {
  const { t, i18n } = useTranslation();
  const currentLanguage =
    languages.find((x) => x.lang === i18n.language) || languages[0];
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="language-panel-tooltip">
              {t("select-language")}
            </Tooltip>
          }
        >
          <div
            className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
            tabIndex="0"
          >
            <div
              className="rounded"
              style={{ width: "40px", fontWeight: "600" }}
            >
              {currentLanguage.short}
            </div>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="navi navi-hover py-4">
          {languages?.map((language) => (
            <li
              key={language?.lang}
              className={clsx("navi-item", {
                active: language?.lang === currentLanguage.lang,
              })}
            >
              <a
                href="#"
                //onClick={() => setLanguage(language.lang)}
                onClick={() => i18n.changeLanguage(language.lang)}
                className="navi-link"
              >
                {/* <span className="symbol symbol-20 mr-3">
                  <img src={language.flag} alt={language.name} />
                </span> */}
                <span className="navi-text">{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
